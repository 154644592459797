import * as React from 'react'

function NotFoundPage() {
  if (typeof window !== 'undefined') {
    window.location.pathname = '/';
  }
  return <h1>404</h1>;
}

export default NotFoundPage
